.bootstrap-dialog {

    .modal-header {
        border-top-left-radius: $border-radius-base;
        border-top-right-radius: $border-radius-base;
    }
    .bootstrap-dialog-title {
        color: #fff;
        display: inline-block;
        font-size: 16px;
    }
    .bootstrap-dialog-message {
        font-size: 14px;
    }
    .bootstrap-dialog-button-icon {
        margin-right: 3px;
    }
    .bootstrap-dialog-close-button {
        font-size: 20px;
        float: right;
        filter:alpha(opacity=90);
        -moz-opacity:0.9;
        -khtml-opacity: 0.9;
        opacity: 0.9;
        &:hover {
            cursor: pointer;
            filter: alpha(opacity=100);
            -moz-opacity: 1;
            -khtml-opacity: 1;
            opacity: 1;
        }
    }

    /* dialog types */
    &.type-default {
        .modal-header {
            background-color: #fff;
        }
        .bootstrap-dialog-title {
            color: #333;
        }
    }

    &.type-info {
        .modal-header {
            background-color: $brand-info;
        }
    }

    &.type-primary {
        .modal-header {
            background-color: $brand-primary;
        }
    }

    &.type-success {
        .modal-header {
            background-color: $brand-success;
        }
    }

    &.type-warning {
        .modal-header {
            background-color: $brand-warning;
        }
    }

    &.type-danger {
        .modal-header {
            background-color: $brand-danger;
        }
    }

    &.size-large {
        .bootstrap-dialog-title {
            font-size: 24px;
        }
        .bootstrap-dialog-close-button {
            font-size: 30px;
        }
        .bootstrap-dialog-message {
            font-size: 18px;
        }
    }
}
