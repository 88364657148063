@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@import "node_modules/font-awesome/scss/font-awesome";
@import "resources/assets/css/multi-select";
@import "plugins/bootstrap-dialog/bootstrap-dialog";

html {
  min-height: 100%;
  position: relative;
}

body {
  margin-bottom: 280px;
  .content {
    padding-top: 30px;
  }
}

footer {
  margin-top: 80px;
  padding: $line-height-computed;
}
.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 200px;
}

/*
 * Responsive text aligning
 * http://ohryan.ca/2014/08/14/set-responsive-text-alignment-bootstrap-3/
 */
.text-xs-left { text-align: left; }
.text-xs-right { text-align: right; }
.text-xs-center { text-align: center; }
.text-xs-justify { text-align: justify; }

@media (min-width: $screen-sm-min) {
  .text-sm-left { text-align: left; }
  .text-sm-right { text-align: right; }
  .text-sm-center { text-align: center; }
  .text-sm-justify { text-align: justify; }
}

@media (min-width: $screen-md-min) {
  .text-md-left { text-align: left; }
  .text-md-right { text-align: right; }
  .text-md-center { text-align: center; }
  .text-md-justify { text-align: justify; }
}

@media (min-width: $screen-lg-min) {
  .text-lg-left { text-align: left; }
  .text-lg-right { text-align: right; }
  .text-lg-center { text-align: center; }
  .text-lg-justify { text-align: justify; }
}

// styled file input buttons
.btn-file {
  position: relative;
  overflow: hidden;

  input[type=file] {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 100%;
    min-height: 100%;
    font-size: 100px;
    text-align: right;
    filter: alpha(opacity=0);
    opacity: 0;
    outline: none;
    background: white;
    cursor: inherit;
    display: block;
  }
}

//  full-calendar
.fc-state-highlight {
  border-color: #66afe9;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(102, 175, 233, 1);
  outline: 1px solid #66afe9;
}

// Submit Incident Form
.submit-incident-form {
  .input-row {
    margin-bottom: 10px;
  }
  @media(max-width: $screen-sm-min) {
    .input-col {
      margin-bottom: 10px;
    }
  }
}